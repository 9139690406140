import React from 'react';
import PropTypes from 'prop-types';
import { RhinoSwitch } from 'rhinostyle';
import ExpandableBox from './ExpandableBox';
import VariableMessage from './VariableMessage';
import { APPOINTMENT_REMINDERS_AUTOMATED_VARIABLE_OPTIONS } from '../constants/VariableMessageConstants';

const GLOBAL_COLOR_SUCCESS = '#82bc00';
const BACKGROUND_GREY = '#404040';

const AppointmentCampaignAutomatedResponse = ({ appointmentInputs, handleChange, errors }) => {
  const name = 'appointmentReminder';
  const confirmationName = `${name}ConfirmationResponseEnabled`;
  const cancelName = `${name}CancelResponseEnabled`;
  const confirmationTemplate = `${name}ConfirmationResponseTemplate`;
  const cancelTemplate = `${name}CancelResponseTemplate`;

  const confirmationEnabled = appointmentInputs.appointmentReminderConfirmationResponseEnabled;
  const cancelEnabled = appointmentInputs.appointmentReminderCancelResponseEnabled;

  const getColor = (isEnabled) => (isEnabled ? GLOBAL_COLOR_SUCCESS : BACKGROUND_GREY);

  const StatusSpan = ({ label, isEnabled }) => (
    <>
      <span className="u-font-weight u-p-r-xsmall">{label}:</span>
      <span
        style={{ color: getColor(isEnabled) }}
      >
        {isEnabled ? 'Enabled' : 'Disabled'}
      </span>
    </>
  );

  const automatedResponseStatus = (
    <>
      <StatusSpan label="Confirmation Response" isEnabled={confirmationEnabled} />
      {' | '}
      <StatusSpan label="Cancel Response" isEnabled={cancelEnabled} />
    </>
  );

  return (
    <ExpandableBox
      title="GENERATE AUTOMATED RESPONSE MESSAGES"
      subtitle="Automated text message will be delivered to patients upon receipt of a codified response"
      dataFeatureTag=""
      description={automatedResponseStatus}
    >
      <div className="summary-panel__section" />

      <RhinoSwitch
        name={confirmationName}
        isChecked={appointmentInputs.appointmentReminderConfirmationResponseEnabled}
        onChange={handleChange}
        label="Enable CONFIRMATION Response Message"
        dataFeatureTag="edit-appointment-reminder_toggle"
      />
      <VariableMessage
        composeLabel="Message"
        className="u-m-t-large"
        name={confirmationTemplate}
        handleTemplateChange={handleChange}
        variableOptions={APPOINTMENT_REMINDERS_AUTOMATED_VARIABLE_OPTIONS || []}
        template={appointmentInputs.appointmentReminderConfirmationResponseTemplate}
        validationMessage={errors[confirmationTemplate]}
        preventFocusOnLoad
        hideAttachments
        source="templates"
        isReadOnly={!appointmentInputs.appointmentReminderConfirmationResponseEnabled}
      />
      <RhinoSwitch
        name={cancelName}
        isChecked={appointmentInputs.appointmentReminderCancelResponseEnabled}
        onChange={handleChange}
        label="Enable CANCEL Response Message"
        dataFeatureTag="edit-appointment-reminder_toggle"
      />
      <VariableMessage
        composeLabel="Message"
        className="u-m-t-large"
        name={cancelTemplate}
        handleTemplateChange={handleChange}
        variableOptions={APPOINTMENT_REMINDERS_AUTOMATED_VARIABLE_OPTIONS || []}
        template={appointmentInputs.appointmentReminderCancelResponseTemplate}
        validationMessage={errors[cancelTemplate]}
        preventFocusOnLoad
        hideAttachments
        source="templates"
        isReadOnly={!appointmentInputs.appointmentReminderCancelResponseEnabled}
      />
    </ExpandableBox>
  );
};

AppointmentCampaignAutomatedResponse.propTypes = {
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  appointmentInputs: PropTypes.shape({
    appointmentReminderConfirmationResponseEnabled: PropTypes.bool.isRequired,
    appointmentReminderCancelResponseEnabled: PropTypes.bool.isRequired,
    appointmentReminderConfirmationResponseTemplate: PropTypes.string,
    appointmentReminderCancelResponseTemplate: PropTypes.string,
  }).isRequired,
};

export default AppointmentCampaignAutomatedResponse;
