import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import cx from 'classnames';
import {
  Button,
  Icon,
  Input,
  CheckboxGroup,
  Checkbox,
  Select,
} from 'rhinostyle';
import moment from 'moment-timezone';

import * as SavedContentReducer from '../reducers/savedContentReducer';
import * as UIReducer from '../reducers/uiReducer';
import * as ThreadReducer from '../reducers/threadReducer';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { UIHelpers, OrganizationHelpers } from '../helpers';
import { PANEL_OPTIONS } from '../constants/ThreadConstants';
import unsavedChanges from './UnsavedChangesHOC';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { getActiveUser } from '../selectors/userSelectors';
import { useThreadEvents } from '../hooks';
import { TYPE_INTEGRATION_EPIC, TYPE_USER_OTHER } from '../constants/Types';
import { fullTimestamp } from '../helpers/DateHelpers';
import UserSearchWithConnectedParties from './UserSearchWithConnectedParties';
import { cloneDeep } from '../helpers/DataHelpers';

const InboxThreadSavedContentForm = (props) => {
  const dispatch = useDispatch();
  const [isMobileSelectionFormVisible, setIsMobileSelectionFormVisible] = useState(false);
  const [contentSelectionFormErrors, setContentSelectionFormErrors] = useState({});
  const [contentSelectionTitle, setContentSelectionTitle] = useState('');
  const [contentSelectionNote, setContentSelectionNote] = useState('');
  const [autoSendToEHR, setAutoSendToEHR] = useState(false);
  const [isContentSelectionFormInProgress, setIsContentSelectionFormInProgress] = useState(false);
  const [savedContentOfficeId, setSavedContentOfficeId] = useState(-1);
  const [encounterDate, setEncounterDate] = useState('');
  const [selectedContactId, setSelectedContactId] = useState(null);

  const {
    isSelectionPreviewModeEnabled,
    isSelectionModeEnabled,
    selectedEventIds,
    activeUserId,
    currentOrganization,
    activeUser,
    officesList,
  } = props;

  const query = useThreadEvents();
  const { data } = query;
  const { entities } = data || {};

  const isEpicIntegration = OrganizationHelpers.isSpecifiedIntegrationType(currentOrganization, TYPE_INTEGRATION_EPIC);
  const isActiveUserOtherType = activeUser.typeId === TYPE_USER_OTHER;

  useEffect(() => () => dispatch(SavedContentReducer.setIsSelectionModeEnabled(false)), []);

  useEffect(() => {
    if (contentSelectionNote || contentSelectionTitle || selectedEventIds?.length > 0) dispatch(UIReducer.handleFormChanges());
  }, [contentSelectionNote, contentSelectionTitle, selectedEventIds]);

  useEffect(() => {
    if (entities && selectedEventIds.length > 0) {
      const clonedSelectedEventIds = cloneDeep(selectedEventIds);
      clonedSelectedEventIds.sort((a, b) => a - b);
      const eventId = clonedSelectedEventIds[0];
      setEncounterDate(entities[eventId]?.timestamp);
    }
  }, [selectedEventIds]);

  function handleToggleMobileSelectionForm() {
    setIsMobileSelectionFormVisible((current) => !current);
  }

  const handleSaveContentSelection = () => {
    setContentSelectionFormErrors({});
    const errors = ValidationService(ValidationShapers.shapeThreadContentSelection({
      selectedEventIds,
      contentSelectionNote,
      contentSelectionTitle,
      savedContentOfficeId,
      autoSendToEHR,
      isEpicIntegration,
      isActiveUserOtherType,
      selectedContactId,
    }));
    const errorCount = Object.keys(errors).length;
    if (errorCount > 0) {
      setContentSelectionFormErrors(errors);
      setIsMobileSelectionFormVisible(true);
    } else {
      const contentSelectionPayload = {
        eventIds: selectedEventIds,
        note: contentSelectionNote,
        title: contentSelectionTitle,
        userId: activeUserId,
        timeZone: moment.tz.guess(),
        autoSendToEHR,
      };

      if (isEpicIntegration) {
        contentSelectionPayload.encounterDate = encounterDate;
      }

      if (autoSendToEHR && isEpicIntegration) {
        contentSelectionPayload.selectedContactId = selectedContactId;
        contentSelectionPayload.savedContentOfficeId = savedContentOfficeId;
      }
      setIsContentSelectionFormInProgress(true);
      dispatch(SavedContentReducer.createSavedContent(contentSelectionPayload))
        .then(() => {
          resetToDefault();
          dispatch(UIReducer.resetFormChanges());
          dispatch(ThreadReducer.setActivePanel(PANEL_OPTIONS.savedContent));
          if (UIHelpers.panelBreakpointMax()) {
            dispatch(ThreadReducer.toggleProfile());
          }
        });
    }
  };

  function resetToDefault() {
    setContentSelectionTitle('');
    setContentSelectionNote('');
    setIsMobileSelectionFormVisible(false);
    setContentSelectionFormErrors({});
    setIsContentSelectionFormInProgress(false);
    setAutoSendToEHR(false);
  }

  const handleToggleSelectionPreviewMode = () => {
    dispatch(SavedContentReducer.setIsSelectionPreviewModeEnabled());
  };
  const handleToggleContentSelectionMode = () => {
    dispatch(SavedContentReducer.updateContentSelectionMode(isSelectionModeEnabled));
  };

  const handleTitleChange = (name, value) => {
    setContentSelectionTitle(value);
  };

  const handleAutoSendChange = (name, value) => {
    setAutoSendToEHR(value);
  };

  const handleNoteChange = (name, value) => {
    setContentSelectionNote(value);
  };

  const handleOfficeChange = (name, value) => {
    setSavedContentOfficeId(value);
  };

  const handleContactSelect = (contactId) => {
    setSelectedContactId(contactId);
  };

  const selectionFormBodyClasses = cx('convo__selection-form__body', {
    'convo__selection-form__body--mobile-is-visible': isMobileSelectionFormVisible,
  });
  return (
    <>
      {isSelectionPreviewModeEnabled ? (
        <div className="convo__footer">
          <div className="convo__footer__alert convo__footer__alert--preview">
            <p className="u-text-accent u-text-italic u-m-b-0 u-m-t">Preview Only</p>
            <Button type="link" className="u-m-b-small" size="small" onClick={handleToggleSelectionPreviewMode}>
              Close Preview
            </Button>
          </div>
        </div>
      ) : (
        <div className="convo__footer convo__selection-form__wrapper">
          <form className="form">
            <div
              className="convo__selection-form__toggle-button"
              onClick={handleToggleMobileSelectionForm}
            >
              <Icon icon={isMobileSelectionFormVisible ? 'caret-down' : 'caret-up'} className="u-text-muted u-m-b-small" />
            </div>
            <div className="convo__selection-form__header">
              <div>
                <small className={contentSelectionFormErrors.selectedEventIds ? 'u-text-danger' : ''}>
                  {selectedEventIds.length} selected
                  {selectedEventIds.length ? (
                    <span>&nbsp;(<Button reset className="u-text-primary" size="small" onClick={handleToggleSelectionPreviewMode}>preview</Button>)</span>
                  ) : null}
                  {contentSelectionFormErrors.selectedEventIds && (<span className="u-m-a">{contentSelectionFormErrors.selectedEventIds}</span>)}
                </small>
              </div>
              {isEpicIntegration && selectedEventIds.length > 0 && (
                <small className="saved-content__encounter-date">
                  Encounter Date: <strong>{fullTimestamp(encounterDate, 'ddd, MMM D, Y, h:mm a')}</strong>
                </small>
              )}
            </div>
            <div className={selectionFormBodyClasses}>
              <div className="row form__group">
                <div className="column-6@medium">
                  <Input
                    name="contentSelectionTitle"
                    label="Selected Content Title"
                    initialValue={contentSelectionTitle}
                    placeholder=""
                    type="text"
                    required
                    onChange={handleTitleChange}
                    validationMessage={contentSelectionFormErrors.contentSelectionTitle}
                  />
                </div>
                <div className="column-6@medium">
                  <Input
                    name="contentSelectionNote"
                    label="Note"
                    initialValue={contentSelectionNote}
                    placeholder=""
                    type="text"
                    onChange={handleNoteChange}
                  />
                </div>
              </div>
              {isEpicIntegration && (
                <div className="row form__group">
                  <div className="column-6@medium">
                    <Select
                      onSelect={handleOfficeChange}
                      label="Office ID"
                      name="savedContentOfficeId"
                      options={officesList}
                      selected={savedContentOfficeId}
                      className="convo__selection-form__offices"
                      required
                      validationMessage={contentSelectionFormErrors.savedContentOfficeId}
                      disabled={!autoSendToEHR}
                    />
                  </div>
                  <div className="column-6@medium">
                    <UserSearchWithConnectedParties
                      activeUser={activeUser}
                      handleContactSelect={handleContactSelect}
                      disabled={!autoSendToEHR}
                      contactErrors={contentSelectionFormErrors}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="convo__selection-form__footer form__group">
              {OrganizationHelpers.canSendToEHR(currentOrganization, activeUser) && (
                <div className="column-8@medium">
                  <CheckboxGroup>
                    <Checkbox
                      className="u-text-small"
                      name="autoSendToEHR"
                      onChange={handleAutoSendChange}
                      isChecked={autoSendToEHR}
                      label="Automatically Send Selected Content to Patient's EHR/PMS"
                    />
                  </CheckboxGroup>
                </div>
              )}
              <div className="u-m-l-auto">
                <Button type="link-muted" onClick={handleToggleContentSelectionMode}>Cancel</Button>
                <Button
                  type="secondary"
                  loading={isContentSelectionFormInProgress}
                  onClick={handleSaveContentSelection}
                >
                  Save Selection
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

InboxThreadSavedContentForm.propTypes = {
  isSelectionModeEnabled: PropTypes.bool.isRequired,
  isSelectionPreviewModeEnabled: PropTypes.bool.isRequired,
  selectedEventIds: PropTypes.array,
  activeUserId: PropTypes.number.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  activeUser: PropTypes.object.isRequired,
  officesList: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { office } = state;
  const { offices, officeIds } = office;
  const officesList = officeIds?.map((o) => ({ id: offices[o]?.id, value: offices[o]?.name }));
  officesList?.unshift({ id: -1, value: '--' });
  const currentOrganization = getCurrentOrg(state);
  const activeUser = getActiveUser(state);

  return {
    isSelectionPreviewModeEnabled: state.savedContent.isSelectionPreviewModeEnabled,
    isSelectionModeEnabled: state.savedContent.isSelectionModeEnabled,
    selectedEventIds: state.savedContent.selectedEventIds,
    activeUserId: state.inbox.userId,
    currentOrganization,
    activeUser,
    officesList,
  };
};

export default connect(mapStateToProps)(unsavedChanges(InboxThreadSavedContentForm));
